import React, { Component } from 'react';
import { assign, debounce, omit, noop } from 'lodash';
import PropTypes from 'prop-types';

// forked from: https://github.com/eiriklv/react-masonry-component/blob/master/lib/index.js

let Masonry;
let imagesloaded;

class MasonryComponent extends Component {
  masonry = false;
  erd = undefined;
  latestKnownDomChildren = [];
  imagesLoadedCancelRef = undefined;

  constructor(props) {
    super(props);
    if (typeof Masonry === 'undefined') {
      const isBrowser = typeof window !== 'undefined';
      Masonry = isBrowser ? window.Masonry || require('masonry-layout') : null;
      imagesloaded = isBrowser ? require('imagesloaded') : null;
    }
  }

  initializeMasonry(force) {
    if (!this.masonry || force) {
      this.masonry = new Masonry(this.masonryContainer, this.props.options);

      if (this.props.onLayoutComplete) {
        this.masonry.on('layoutComplete', this.props.onLayoutComplete);
      }

      if (this.props.onRemoveComplete) {
        this.masonry.on('removeComplete', this.props.onRemoveComplete);
      }

      this.latestKnownDomChildren = this.getCurrentDomChildren();
    }
  }

  getCurrentDomChildren() {
    const node = this.masonryContainer;
    const children = this.props.options?.itemSelector
      ? node.querySelectorAll(this.props.options?.itemSelector)
      : node.children;
    return Array.prototype.slice.call(children);
  }

  diffDomChildren() {
    let forceItemReload = false;

    const knownChildrenStillAttached = this.latestKnownDomChildren.filter(
      ({ parentNode }) =>
        /*
         * take only elements attached to DOM
         * (aka the parent is the masonry container, not null)
         * otherwise masonry would try to "remove it" again from the DOM
         */ !!parentNode,
    );

    /*
     * If not all known children are attached to the dom - we have no other way of notifying
     * masonry to remove the ones not still attached besides invoking a complete item reload.
     * basically all the rest of the code below does not matter in that case.
     */
    if (
      knownChildrenStillAttached.length !== this.latestKnownDomChildren.length
    ) {
      forceItemReload = true;
    }

    const currentDomChildren = this.getCurrentDomChildren();

    /*
     * Since we are looking for a known child which is also attached to the dom AND
     * not attached to the dom at the same time - this would *always* produce an empty array.
     */
    const removed = knownChildrenStillAttached.filter(
      (attachedKnownChild) => !~currentDomChildren.indexOf(attachedKnownChild),
    );

    /*
     * This would get any children which are attached to the dom but are *unkown* to us
     * from previous renders
     */
    const newDomChildren = currentDomChildren.filter(
      (currentChild) => !~knownChildrenStillAttached.indexOf(currentChild),
    );

    let beginningIndex = 0;

    // get everything added to the beginning of the DOMNode list
    const prepended = newDomChildren.filter((newChild) => {
      const prepend = beginningIndex === currentDomChildren.indexOf(newChild);

      if (prepend) {
        // increase the index
        beginningIndex++;
      }

      return prepend;
    });

    // we assume that everything else is appended
    const appended = newDomChildren.filter((el) => !prepended.includes(el));

    /*
     * otherwise we reverse it because so we're going through the list picking off the items that
     * have been added at the end of the list. this complex logic is preserved in case it needs to be
     * invoked
     *
     * var endingIndex = currentDomChildren.length - 1;
     *
     * newDomChildren.reverse().filter(function(newChild, i){
     *     var append = endingIndex == currentDomChildren.indexOf(newChild);
     *
     *     if (append) {
     *         endingIndex--;
     *     }
     *
     *     return append;
     * });
     */

    // get everything added to the end of the DOMNode list
    let moved = [];

    /*
     * This would always be true (see above about the lofic for "removed")
     */
    if (removed.length === 0) {
      /*
       * 'moved' will contain some random elements (if any) since the "knownChildrenStillAttached" is a filter
       * of the "known" children which are still attached - All indexes could basically change. (for example
       * if the first element is not attached)
       * Don't trust this array.
       */
      moved = knownChildrenStillAttached.filter(
        (child, index) => index !== currentDomChildren.indexOf(child),
      );
    }

    this.latestKnownDomChildren = currentDomChildren;

    return {
      old: knownChildrenStillAttached, // Not used
      new: currentDomChildren, // Not used
      removed,
      appended,
      prepended,
      moved,
      forceItemReload,
    };
  }

  performLayout() {
    const diff = this.diffDomChildren();
    let reloadItems = diff.forceItemReload || diff.moved.length > 0;

    // Would never be true. (see comments of 'diffDomChildren' about 'removed')
    if (diff.removed.length > 0) {
      this.masonry.remove(diff.removed);
      reloadItems = true;
    }

    if (diff.appended.length > 0) {
      this.masonry.appended(diff.appended);

      if (diff.prepended.length === 0) {
        reloadItems = true;
      }
    }

    if (diff.prepended.length > 0) {
      this.masonry.prepended(diff.prepended);
    }

    if (reloadItems) {
      this.masonry.reloadItems();
    }

    this.masonry.layout();
  }

  derefImagesLoaded() {
    this.imagesLoadedCancelRef();
    this.imagesLoadedCancelRef = undefined;
  }

  imagesLoaded() {
    const {
      disableImagesLoaded = false,
      updateOnEachImageLoad = false,
      imagesLoadedOptions = {},
      onImagesLoaded,
    } = this.props;

    if (disableImagesLoaded) {
      return;
    }

    if (this.imagesLoadedCancelRef) {
      this.derefImagesLoaded();
    }

    const event = updateOnEachImageLoad ? 'progress' : 'always';
    const handler = debounce((instance) => {
      if (onImagesLoaded) {
        onImagesLoaded(instance);
      }
      this.masonry.layout();
    }, 100);

    const imgLoad = imagesloaded(
      this.masonryContainer,
      imagesLoadedOptions,
    ).on(event, handler);

    this.imagesLoadedCancelRef = () => {
      imgLoad.off(event, handler);
      handler.cancel();
    };
  }

  destroyErd() {
    if (this.erd) {
      this.latestKnownDomChildren.forEach(this.erd.uninstall, this.erd);
    }
  }

  componentDidMount() {
    this.initializeMasonry();
    this.imagesLoaded();
  }

  componentDidUpdate() {
    this.performLayout();
    this.imagesLoaded();
  }

  componentWillUnmount() {
    this.destroyErd();

    // unregister events
    if (this.props.onLayoutComplete) {
      this.masonry.off('layoutComplete', this.props.onLayoutComplete);
    }

    if (this.props.onRemoveComplete) {
      this.masonry.off('removeComplete', this.props.onRemoveComplete);
    }

    if (this.imagesLoadedCancelRef) {
      this.derefImagesLoaded();
    }
    this.masonry.destroy();
  }

  setRef = (n) => (this.masonryContainer = n);

  render() {
    const props = omit(this.props, Object.keys(propTypes));
    return React.createElement(
      this.props.elementType || 'div',
      assign({}, props, { ref: this.setRef }),
      this.props.children,
    );
  }
}

const propTypes = {
  disableImagesLoaded: PropTypes.bool,
  onImagesLoaded: PropTypes.func,
  updateOnEachImageLoad: PropTypes.bool,
  options: PropTypes.object,
  imagesLoadedOptions: PropTypes.object,
  elementType: PropTypes.string,
  onLayoutComplete: PropTypes.func,
  onRemoveComplete: PropTypes.func,
  children: PropTypes.any,
};

MasonryComponent.propTypes = propTypes;

export default MasonryComponent;
